<template>
  <div>
    <div id=remerciement-core>
      <h1 id="titreRemerciement">
        {{ $t("aknowledgmentsTitle") }}
      </h1>
      <div id="descRemerciement" v-html="$t('aknowledgments1')"></div>
      <b-container>
        <b-row id="sectionImage">
          <b-col md="4">
            <div id="image">
              <a href="http://www.dfo-mpo.gc.ca/index-fra.htm"><img class="imageMPO" :src="`../assets/mpo-Fr.svg`" v-if="$i18n.locale === 'fr'"/></a>
              <a href="https://www.dfo-mpo.gc.ca/index-eng.html"><img class="imageMPO" :src="`../assets/mpo_En.svg`" v-if="$i18n.locale === 'en'"/></a>
            </div>
          </b-col>
          <b-col md="4">
            <div id="image">
              <a href="https://www.uqar.ca/"><img class="imageUQAR" :src="`../assets/uqar.svg`"/></a>
            </div>
          </b-col>
          <b-col md="4">
            <div id="image">
              <a href="https://ogsl.ca/fr/accueil/"><img class="imageOGSL" :src="`../assets/OGSL_Logo.svg`" v-if="$i18n.locale === 'fr'"/></a>
              <a href="https://ogsl.ca/en/home-slgo/"><img class="imageOGSL" :src="`../assets/SLGO_Logo.svg`" v-if="$i18n.locale === 'en'"/></a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Remerciement"
}
</script>

<style>
  #titreRemerciement {
    color: #193835;
    font-weight: bold;
    padding: 0 1rem;
  }

  #descRemerciement {
    color: #193835;
    padding: 0.5rem 1rem;
  }

  #remerciement-core {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  #sectionImage {
    align-items: center;
  }

  #image {
    margin: 1rem 0;
  }

  #image img {
    height: 100px;
    width: 100%;
  }

  @media (max-width: 510px) {
    #remerciement-core {
      padding: 0 0.5rem;
    }
  }
</style>