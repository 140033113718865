<template>
  <div class="opacity-slider-container">
    <div class="opacity-title">
      <div>{{ $t("opacityTitle") }}</div>
    </div>
    <div class="slider-value">
      <input
        class="opacity-slider"
        type="range"
        orient="vertical"
        min="0"
        max="100"
        step="1"
        v-model="value"
      />
      <div class="value">{{ value }}%</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { value: 75 };
  },
  watch: {
    value: function () {
      this.$emit("opacity-value", this.value);
    },
  },
};
</script>

<style scoped>
.opacity-slider-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1;
  top: 100px;
  right: 30px;
  width: 60px;
  height: 140px;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  font-weight: bold;
  padding: 2px;
  border-radius: 7px;
}
input[type="range"][orient="vertical"] {
  writing-mode: bt-lr; /* IE */
  -webkit-appearance: slider-vertical;
}

.opacity-slider {
  bottom: 40px;
  right: -20px;
  width: 30px;
  height: 90px;
}
.slider-value {
  display: flex;
  flex-wrap: wrap;
  width: 30px;
}
.value {
  text-align: center;
  width: 30px;
  font-size: 12px;
}
.opacity-title {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.opacity-title > div {
  color: #fff;
  font-size: 13px;
  transform: rotate(-90deg);
  white-space: nowrap;
}

@media (max-width: 575px) {
  .opacity-slider-container {
    top: 70px;
    right: 20px;
    width: 45px;
    height: 100px;
  }
  .opacity-slider {
    height: 70px;
  }
  .slider-value {
    width: 25px;
  }
  .value {
    width: 25px;
    font-size: 9px;
  }
  .opacity-title {
    width: 15px;
  }
  .opacity-title > div {
    font-size: 9px;
  }
}
</style>