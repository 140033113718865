<template>
  <div>
    <div class="time-buttons">
      <DateSelectorButton
        :timeRange="'TODAY'"
        :activeButton="activeButton"
        v-on:activeButtonFromChild="handleButtonClick"
      ></DateSelectorButton>
      <div :class="{ cursorNotAllowed: buttonDisabled === true }">
        <DateSelectorButton
          :class="{ buttonDisabled: buttonDisabled === true }"
          :timeRange="'TOMORROW'"
          :activeButton="activeButton"
          v-on:activeButtonFromChild="handleButtonClick"
        ></DateSelectorButton>
      </div>
    </div>
    <AlgaeInfoPane
      v-bind:pointerData="pointerData"
      v-bind:activeButton="activeButton"
      @disableButton="disableButton"
    ></AlgaeInfoPane>
    <TheOpacitySlider @opacity-value="changeOpacity"></TheOpacitySlider>
  </div>
</template>

<script>
import { Layers } from "@/constants";
import AlgaeInfoPane from "@/components/AlgaeInfoPane.vue";
import DateSelectorButton from "@/components/DateSelectorButton.vue";
import TheOpacitySlider from "@/components/TheOpacitySlider.vue";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import { fromLonLat, toLonLat } from "ol/proj";
import XYZ from "ol/source/XYZ";

export default {
  components: {
    AlgaeInfoPane,
    DateSelectorButton,
    TheOpacitySlider,
  },
  data() {
    return {
      activeButton: Layers["TODAY"][0],
      map: {},
      view: {},
      alguesSource: {},
      alguesWMS: {},
      pointerData: { prediction: null, coordinates: [] },
      buttonDisabled: false,
    };
  },
  methods: {
    changeOpacity(value) {
      this.alguesWMS.setOpacity(value / 100);
    },
    getAlguesInfo(coordinates, view, source) {
      let _this = this;
      const viewResolution = /** @type {number} */ (view.getResolution());
      const url = source.getFeatureInfoUrl(
        coordinates,
        viewResolution,
        "EPSG:3857",
        { INFO_FORMAT: "application/json" }
      );
      if (url) {
        fetch(url)
          .then((response) => response.json())
          .then((json) => {
            if (json.features.length > 0) {
              let objProperties = json.features[0].properties
              _this.pointerData.prediction = objProperties[Object.keys(objProperties)[0]]
              _this.pointerData.coordinates = toLonLat(coordinates);
            }
          });
      }
    },
    handleButtonClick(buttonName) {
      this.activeButton = buttonName;
    },
    disableButton() {
      this.buttonDisabled = true;
    },
  },
  mounted() {
    let _this = this;
    /*var raster = new TileLayer({
      source: new BingMaps({
        key: "AvNf2K1e2y9htQc1-Pz0ODRxstgG4DgmevXmUsh0li-jmpiJP65ZhOPsx44PfBGu",
        imagerySet: "AerialWithLabelsOnDemand",
      }),
    });
    var raster = new TileLayer({
      className: "bw",
      source: new OSM(),
    });*/
    var raster = new TileLayer({
      source: new XYZ({
        url: "https://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        attributions:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      }),
    });
    this.view = new View({
      center: fromLonLat([-65, 49]),
      zoom: 6.5,
    });

    this.map = new Map({
      layers: [raster],
      target: "map",
      view: this.view,
    });

    this.alguesSource = new TileWMS({
      url: `${process.env.VUE_APP_GEOSERVER_URL}${process.env.VUE_APP_GEOSERVER_WORKSPACE}/wms`,
      params: {
        LAYERS: `${process.env.VUE_APP_GEOSERVER_WORKSPACE}:${process.env.VUE_APP_GEOSERVER_FIRST_LAYER_NAME}`,
        TILED: true,
      },
      serverType: "geoserver",
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    });

    this.alguesWMS = new TileLayer({
      name: "mpo-algues-layer",
      source: this.alguesSource,
      opacity: 0.75,
    });
    this.map.addLayer(this.alguesWMS);

    this.map.on("pointermove", function (e) {
      _this.getAlguesInfo(
        e.coordinate,
        _this.view,
        _this.alguesWMS.getSource()
      );
    });
    this.map.on("click", function (e) {
      _this.getAlguesInfo(
        e.coordinate,
        _this.view,
        _this.alguesWMS.getSource()
      );
    });
  },
  watch: {
    activeButton: function (newSelectedLayer) {
      this.alguesWMS.getSource().updateParams({
        LAYERS: `${process.env.VUE_APP_GEOSERVER_WORKSPACE}:${newSelectedLayer}`,
      });
    },
  },
};
</script>

<style>
.time-buttons {
  position: absolute;
  display: flex;
  top: 10px;
  right: 20px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}
.buttonDisabled {
  pointer-events: none;
  background-color: grey;
}
.cursorNotAllowed{
  cursor: not-allowed;
}
</style>