<template>
  <div
    class="time-button"
    @click="$emit('activeButtonFromChild', nameTime)"
    v-bind:class="{
      active: activeButton === nameTime,
    }"
  >
    <div v-if="$i18n.locale === 'en'" class="map-button-change-pred">Next</div>
    <div class="title-time map-button-change-pred">{{ titleTimeNumber }}</div>
    <div class="map-button-change-pred">{{ $t("next-hours") }}</div>
  </div>
</template>

<script>
import { Layers } from "@/constants";
export default {
  props: ["timeRange", "activeButton"],
  data() {
    return {
      titleTimeNumber: Layers[this.timeRange][1],
      nameTime: Layers[this.timeRange][0],
    };
  },
};
</script>

<style>
.time-button {
  width: 80px;
  height: 68px;
  margin: 5px;
  text-align: center;
  background-color: #193835;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  cursor: pointer;
  font-size:15px;
  padding-top:3px;
}
.title-time {
  font-size: 20px;
  font-weight: bold;
}
.map-button-change-pred {
  color: white;
  line-height: 1.2em;
}
.time-button:hover .map-button-change-pred {
  color: white;
}
.time-button:hover {
  background-color: #018786;
}
.time-button.active {
  background-color: #018786;
}
.time-button.active .map-button-change-pred {
  color: white;
}
@media (max-width: 575px) {
  .time-button {
  width: 62px;
  height: 47px;
  margin: 3px;
  font-size: 11px;
  padding-top:1px;

}
.title-time {
  font-size: 14px;
  font-weight: bold;
}
}
</style>