import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      if ( window.innerWidth <= 575 ) {
        return {
          selector: to.hash,
          behavior: 'smooth',
          offset: { x: 0, y: 280 }
          // offset: { x: 0, y: 0 }
        }
      } else {
        return {
          selector: to.hash,
          behavior: 'smooth',
          offset: { x: 0, y: 0 }
        };
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  },
})

