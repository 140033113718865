<template>
  <div>
    <div id="graphique-core">
      <h1 id="titreGrahique" v-html="$t('titreGrahique')"></h1>
      <b-container>
        <b-row>
          <b-col md="5">
            <div>
              <div id="descGraphique">
                <img class="imageRegion" :src="`../assets/regionFR.png`" v-if="$i18n.locale === 'fr'"/>
                <img class="imageRegion" :src="`../assets/regionEN.png`" v-if="$i18n.locale === 'en'"/>
              </div>
              <div id="sousTitreDescGraphique">{{ $t("sousTitreDescGraphique1") }}</div>
              <div id="descGraphique">
                {{ $t("descGraphique1") }}
              </div>
              <div id="sousTitreDescGraphique">{{ $t("sousTitreDescGraphique2") }}</div>
              <div id="descGraphique">
                {{ $t("descGraphique2") }}
              </div>
            </div>
          </b-col>
          <b-col md="7">
            <TheGraphique id="graphique"> </TheGraphique>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import TheGraphique from "@/components/TheGraphiqueAndOptions.vue";
export default {
  name: "Graphique",
  components: { TheGraphique },
};
</script>

<style>
#graphique-core .container {
  max-width: none !important;
}
#sousTitreDescGraphique,
#descGraphique {
  color: #E5E3C5;
}

#sousTitreDescGraphique {
  font-weight: bold;
  margin: 1rem 0;
  font-size: 1.4rem;
}

#graphique-core {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 1rem;
}

#titreGrahique {
  padding: 0 1rem;
}
#graphique{
  background-color: white;
}

.imageRegion {
  width: 100%;
}
</style>