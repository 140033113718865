<template>
  <div id="app">
    <div id="warning-bandeau" v-show="noUpToDateData">
      <span>{{ $t("no-data-warning-0") }}</span>
      <span>{{ formattedLatestDate }}</span>
      <span>{{ $t("no-data-warning-1") }}</span>
      <a href="mailto:info@ogsl.ca">{{ $t("no-data-warning-2") }}</a>
      <span>{{ $t("no-data-warning-3") }}</span>
    </div>
    <TheHeader id="sectionHeader"></TheHeader>
    <div id="sectionIntro">

      <div id="sectionIntro-core">
        <h1 id="titreIntro" v-html="$t('intro_title')"></h1>
        <div>
          <b-row row-cols-2>
            <b-col md>
              <div id="descIntro" v-html="$t('intro_desc1')"></div>
              <div id="descIntro" v-html="$t('intro_desc2')"></div>
            </b-col>
            <b-col md>
              <img class="imageAltox" :src="`../assets/altox.png`" />
            </b-col>
          </b-row>
        </div>

      </div>
    </div>
    <div id="sectionMap">
      <div id="sectionMap-core">
        <h1 id="titreCartePredictions">
          {{ $t("predictionMap_title") }}
        </h1>
        <div id="descCartePredictions" v-html="$t('predictionMap_desc1')"></div>
        <div id="descCartePredictions" v-html="$t('predictionMap_desc2')"></div>
        <TheMap id="map"></TheMap>
        <h2 id="titreLegendDetaille">
          {{ $t("detailedLegend_title") }}
        </h2>
        <div>
          <b-row row-cols-5 id="sectionLegendDetaillee">
            <b-col sm>
              <div id="couleurLegend0"></div>
              <div id="titredescLegendDetaille">
                {{ $t('Out_of_bounds') }}
              </div>
              <div id="descLegendDetaille" v-html="$t('descOut_of_bounds')"></div>
            </b-col>
            <b-col sm>
              <div id="couleurLegend1"></div>
              <div id="titredescLegendDetaille">
                {{ $t("unlikely_title") }}
              </div>
              <div id="descLegendDetaille" v-html="$t('unlikely_desc')"></div>
            </b-col>
            <b-col sm>
              <div id="couleurLegend2"></div>
              <div id="titredescLegendDetaille">
                {{ $t("probable_title") }}
              </div>
              <div id="descLegendDetaille" v-html="$t('probable_desc')"></div>
            </b-col>
            <b-col sm>
              <div id="couleurLegend3"></div>
              <div id="titredescLegendDetaille">
                {{ $t("veryProbable_title") }}
              </div>
              <div id="descLegendDetaille" v-html="$t('veryProbable_desc')"></div>
            </b-col>
            <b-col sm>
              <div id="couleurLegend4"></div>
              <div id="titredescLegendDetaille">
                {{ $t("highlyProbable_title") }}
              </div>
              <div id="descLegendDetaille" v-html="$t('highlyProbable_desc')"></div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <TheGraphiqueSection id="sectionGraphique"></TheGraphiqueSection>
    <TheAPropos id="sectionAPropos"></TheAPropos>
    <TheMethodologie id="sectionMethodologie"></TheMethodologie>
    <TheDataArchiving id="sectionDataArchiving"></TheDataArchiving>
    <TheDisclaimerOfLiability id="sectionDisclaimerOfLiability"></TheDisclaimerOfLiability>
    <TheArticles id="sectionArticles"></TheArticles>
    <TheRemerciement id="sectionRemerciement"></TheRemerciement>
  </div>
</template>

<script>
import { formatDateNoHours } from "@/common/dateFormatter";
import TheMap from "./components/TheMap.vue";
import TheHeader from './components/TheHeader.vue';
import TheGraphiqueSection from './components/TheGraphiqueSection.vue';
import TheAPropos from './components/TheAPropos.vue';
import TheMethodologie from './components/TheMethodologie.vue';
import TheRemerciement from './components/TheRemerciement.vue';
import TheDataArchiving from './components/TheDataArchiving.vue';
import TheDisclaimerOfLiability from './components/TheDisclaimerOfLiability.vue';
import TheArticles from './components/TheArticles.vue';
export default {
  name: "App",
  components: {
    TheMap,
    TheHeader,
    TheGraphiqueSection,
    TheAPropos,
    TheMethodologie,
    TheRemerciement,
    TheDataArchiving,
    TheDisclaimerOfLiability,
    TheArticles
  },
  computed:{
    noUpToDateData(){
      let cutoffDate = new Date()
      cutoffDate.setDate(cutoffDate.getDate() - 2);
      console.log(cutoffDate)
      console.log(this.$store.state.latestDate)
      return this.$store.state.latestDate < cutoffDate
    },
    formattedLatestDate(){
      return formatDateNoHours(this.$store.state.latestDate)
    }
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "fr") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "fr";
      }
    },
  },
};
</script>

<style>
#sectionLegendDetaillee .col-sm {
  padding: 0;
}

#sectionLegendDetaillee {
  padding: 0 1rem;
}

#sectionIntro,
#sectionMap,
#sectionGraphique,
#sectionAPropos,
#sectionMethodologie,
#sectionRemerciement,
#sectionArticles,
#sectionDataArchiving,
#sectionDisclaimerOfLiability {
  padding: 1rem 0;
}

h1,
h2 {
  padding: 1rem !important;
}

#titreIntro,
#titreCartePredictions,
#titreGrahique,
#titreLegendDetaille,
#titredescLegendDetaille,
#descLegendDetaille {
  color: #E5E3C5;
}

#descCartePredictions,
#descIntro {
  color: #E5E3C5;
  padding: 0.5rem 1rem;
}

#titreIntro,
#titreCartePredictions,
#titreGrahique,
#titreLegendDetaille {
  font-weight: bold;
}

#titredescLegendDetaille {
  font-weight: bold;
  margin-top: 0.5rem;
  font-size: 1.4rem;
}

#couleurLegend0,
#couleurLegend1,
#couleurLegend2,
#couleurLegend3,
#couleurLegend4 {
  height: 0.75em;
  margin: 0 1rem;

}

#couleurLegend0 {
  background-color: #D4DADC;
}

#couleurLegend1 {
  background-color: #6699cc;
}

#couleurLegend2 {
  background-color: #e6cb20;
}

#couleurLegend3 {
  background-color: #e69120;
}

#couleurLegend4 {
  background-color: #e62020;
}

#map {
  height: 500px;
  position: relative;
}

#map,
#sectionIntro-core,
#sectionMap-core {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 1rem;
}

#descCartePredictions,
#titredescLegendDetaille,
#descLegendDetaille {
  padding: 0 1rem;
}

#descCartePredictions {
  margin-bottom: 0.5rem;
}

#sectionHeader,
#sectionIntro,
#sectionMap,
#sectionGraphique {
  background-color: #193835;
}

.imageAltox {
  width: 100%;
}

@media only screen and (max-width: 680px) and (min-width: 576px) {

  #titredescLegendDetaille,
  #descLegendDetaille {
    padding: 0 0.4rem;
  }

  #titredescLegendDetaille {
    font-size: 1rem;
  }

  #couleurLegend0,
  #couleurLegend1,
  #couleurLegend2,
  #couleurLegend3,
  #couleurLegend4 {
    margin: 0 0.5rem;
  }
}

@media (max-width: 510px) {

  #map,
  #sectionIntro-core,
  #sectionMap-core {
    padding: 0 0.5rem;
  }

  h1,
  .h1 {
    font-size: 2rem !important;
  }

  #warning-bandeau {
    font-size: small;
  }
}

#warning-bandeau {
  background-color: #ffcb1f;
  padding: 0px 10px
}
</style>
