// store.js
import Vue from "vue";

const state = Vue.observable({
    latestDate: new Date(),

});

const mutations = {
    updateLatestDate(date) {
        state.latestDate = date
    },

};

export default {
    state: state,
    mutations,
};
