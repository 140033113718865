<template>
  <div>
    <div id=dataArchiving-core>
      <h1 id="titreDataArchiving">{{ $t("dataArchivingTitle") }}</h1>
      <div id="descMethodologie" v-html="$t('dataArchiving1')"></div>
      <div id="descMethodologie" v-html="$t('dataArchiving2')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataArchiving"
}
</script>

<style>
  #titreDataArchiving {
    color: #193835;
    font-weight: bold;
    padding: 0 1rem;
  }

  #descDataArchiving {
    color: #193835;
    padding: 0.5rem 1rem;
  }

  #soustitreDataArchiving {
    color: #193835;
    font-weight: bold;
    padding: 0 1rem;
    font-size: 1.4rem;
  }

  #dataArchiving-core {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @media (max-width: 510px) {
    #dataArchiving-core {
      padding: 0 0.5rem;
    }
  }
</style>