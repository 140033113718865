<template>
  <div>
    <div>
      <multiselect
        @select="selected"
        ref="vselect"
        v-model="value"
        :options="options"
        group-values="libs"
        group-label="select_all"
        :group-select="true"
        :close-on-select="true"
        :clear-on-select="true"
        :show-labels="false"
        :placeholder="$t('pick-region')"
        :multiple="true"
        :label="titleSelection"
        track-by="name"
      >
        <template slot="tag" slot-scope="{ option }">
          <div
            class="tagParent"
            :style="{ 'background-color': tagBackgroundColor(option) }"
          >
            <strong class="tag-selected-title">{{
              tagTitleSelected(option)
            }}</strong>
            <div
              aria-hidden="true"
              tabindex="1"
              @keydown.enter.prevent="tagRemoveElement(option)"
              @mousedown.prevent="tagRemoveElement(option)"
              class="multiselect__tag-icon custom-remove-tag"
              :style="removeTagStyle(option)"
            ></div>
          </div> </template
      ></multiselect>
    </div>
    <TheGraphique
      id="timeSeriesChart"
      class="svg-container"
      :dataToDisplay="dataToDisplay"
      :dataDateMin="dataDateMin"
      :dataDateMax="dataDateMax"
    ></TheGraphique>
  </div>
</template>

<script>
import { Regions } from "@/constants";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import TheGraphique from "@/components/TheGraphique.vue";
export default {
  components: {
    Multiselect,
    TheGraphique,
  },
  computed: {
    titleSelection() {
      return `title${this.$i18n.locale}`;
    },
    options() {
      let options = [{ select_all: this.$t('selectAll'), libs: Regions }];
      return options
    },
  },
  data() {
    return {
      value: [],
      dataToDisplay: {},
      dataDateMin: "",
      dataDateMax: "",
    };
  },
  methods: {
    selected() {
      this.$refs.vselect.deactivate();
    },
    tagTitleSelected(option) {
      return option[`title${this.$i18n.locale}`];
    },
    fetchData(name) {
      let _this = this;
      this.$axios({
        method: "get",
        //url: `${process.env.VUE_APP_OGSL_ERDDAP}/info/mpo_algues_77af_5ed7_d8e0/index.html`,
        url: `${process.env.VUE_APP_OGSL_ERDDAP}tabledap/mpoAlguesSeriesTemporelles.csv?time,${name}&time>=${_this.dataDateMin}&time<=${_this.dataDateMax}`,
      }).then((response) => {
        let data = response.data.split("\n").slice(2, -1);
        let dataFormatted = [];
        for (let i = 0; i < data.length; i++) {
          let splitData = data[i].split(",");
          let singleDataObj = {
            date: new Date(splitData[0]),
            value: parseFloat(splitData[1]),
          };
          dataFormatted.push(singleDataObj);
        }
        this.$set(this.dataToDisplay, name, dataFormatted);
      });
    },
    getTimeSeriesMetadata() {
      this.$axios({
        method: "get",
        //url: `${process.env.VUE_APP_OGSL_ERDDAP}/info/mpo_algues_77af_5ed7_d8e0/index.html`,
        url:
          process.env.VUE_APP_OGSL_ERDDAP +
          "info/mpoAlguesSeriesTemporelles/index.csv",
      }).then((response) => {
        let array_response = response.data.split("\n");
        for (let i = 0; i < array_response.length; i++) {
          let columns = array_response[i].split(",");
          // if (columns.includes("time_coverage_start")) {
          //   this.dataDateMin = columns[4];
          // }
          // temporal fix
          this.dataDateMin = "2023-04-29T00:00:00Z"
          if (columns.includes("time_coverage_end")) {
            this.dataDateMax = columns[4];
          }
        }
      });
    },
    tagBackgroundColor(option) {
      return option.color;
    },
    removeTagStyle(option) {
      let color = option.darkColor;
      return {
        "--hovercolor": color,
      };
    },
    tagRemoveElement: function (element) {
      this.$refs.vselect.removeElement(element);
    },
  },
  mounted() {
    this.getTimeSeriesMetadata();
  },
  watch: {
    value: function (newValue) {
      for (let i = 0; i < newValue.length; i++) {
        if (!(newValue[i].name in this.dataToDisplay)) {
          this.fetchData(newValue[i].name);
        }
      }
      for (let variable in this.dataToDisplay) {
        if (!newValue.some((e) => e.name === variable)) {
          Vue.delete(this.dataToDisplay, variable);
        }
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.svg-container {
  display: inline-block;
  position: relative;
  width: 100%; /* aspect ratio */
  height: 100%;
  vertical-align: top;
  max-width: 700px;
  border-radius: 10px;
  border: 2px solid var(--gris_pale_ogsl);
  margin: 4px;
}
.custom-remove-tag {
  position: relative;
  display: inline-block;
  margin: 0;
}
.custom-remove-tag:hover {
  background: var(--hovercolor);
}
.tagParent {
  display: inline-block;
  margin: 2px;

  border-radius: 5px;
}
.multiselect__tag-icon:after {
  color: #35495e;
}
.tag-selected-title {
  padding: 5px;
}
</style>