<template>
  <div>
    <div id=articles-core>
      <h1 id="titreArticles">
        {{ $t("articlesTitle") }}
      </h1>
      <div id="descArticles" v-html="$t('articles1')"></div>
      <div id="descArticles" v-html="$t('articles2')"></div>
      <div id="descArticles" v-html="$t('articles3')"></div>
      <div id="descArticles" v-html="$t('articles4')"></div>
      <div id="descArticles" v-html="$t('articles5')"></div>
      <div id="descArticles" v-html="$t('articles6')"></div>
      <div id="descArticles" v-html="$t('articles7')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Articles"
}
</script>

<style>
  #titreArticles {
    color: #193835;
    font-weight: bold;
    padding: 0 1rem;
  }

  #descArticles {
    color: #193835;
    padding: 0.5rem 1rem;
  }

  #articles-core {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @media (max-width: 510px) {
    #articles-core {
      padding: 0 0.5rem;
    }
  }
</style>