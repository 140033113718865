<template>
  <div>
    <div id=DisclaimerOfLiability-core>
      <h1 id="titreDisclaimerOfLiability">{{ $t("dataArchivingSubTitle") }}</h1>
      <div id="descDisclaimerOfLiability" v-html="$t('dataArchiving3')"></div>
      <div id="descDisclaimerOfLiability" v-html="$t('dataArchiving4')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisclaimerOfLiability"
}
</script>

<style>
  #titreDisclaimerOfLiability {
    color: #193835;
    font-weight: bold;
    padding: 0 1rem;
  }

  #descDisclaimerOfLiability {
    color: #193835;
    padding: 0.5rem 1rem;
  }

  #descDisclaimerOfLiabilitye {
    color: #193835;
    font-weight: bold;
    padding: 0 1rem;
    font-size: 1.4rem;
  }

  #DisclaimerOfLiability-core {
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  @media (max-width: 510px) {
    #dataArchiving-core {
      padding: 0 0.5rem;
    }
  }
</style>