import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
// import { BootstrapVue, BootstrapVueIcons  } from 'bootstrap-vue'
import router from "./router";
import Multiselect from "vue-multiselect";
import axios from "axios";
import store from "./store";

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// Vue.use(BootstrapVueIcons)

Vue.prototype.$axios = axios;
Vue.use(router)
Vue.component("multiselect", Multiselect);
Vue.config.productionTip = false
Vue.prototype.$store = store;

new Vue({
  i18n,
  router, 
  render: h => h(App)
}).$mount('#app')
