<template>
  <div class="algae-info-pane">
    <div>
      <p class="pred-message">{{ $t("pred-message") }}</p>
      <p class="percentage">{{ roundedPred }}%</p>
      Date:
      <p class="date-string">{{ dateString }}</p>
      Lat:
      <p class="coordinates">{{ roundedLat }}</p>
      Lon:
      <p class="coordinates">{{ roundedLon }}</p>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/common/dateFormatter";
export default {
  props: ["pointerData", "activeButton"],
  data() {
    return {
      firstLayerTime: null,
      secondLayerTime: null,
    };
  },
  computed: {
    dateString() {
      if (this.firstLayerTime != null) {
        return formatDate(this.firstLayerTime);
      } else {
        return null;
      }
    },
    roundedLon() {
      let lon = Math.round(this.pointerData.coordinates[0] * 100) / 100;
      return lon.toFixed(2);
    },
    roundedLat() {
      let lat = Math.round(this.pointerData.coordinates[1] * 100) / 100;
      return lat.toFixed(2);
    },
    roundedPred() {
      let pred = 0;
      if (this.pointerData.prediction >= 0) {
        pred = Math.round(this.pointerData.prediction);
      }
      return pred;
    },
  },
  methods: {
    getGeoserverTime() {
      let url = `${process.env.VUE_APP_GEOSERVER_URL}${process.env.VUE_APP_GEOSERVER_WORKSPACE}/wms?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetCapabilities`;
      fetch(url)
        .then((response) => response.text())
        .then((str) => new window.DOMParser().parseFromString(str, "text/xml"))
        .then((data) => {
          let layerNodes = data.getElementsByTagName("Layer");
          // On commence à 1, vu que le premier tag "layer" est le tag général, qui contient beaucoup trop d'info
          for (var i = 1; i < layerNodes.length; i++) {
            if (
              layerNodes[i].getElementsByTagName("Name")[0].innerHTML ===
              process.env.VUE_APP_GEOSERVER_FIRST_LAYER_NAME
            ) {
              this.firstLayerTime = this.getTimeFromXML(layerNodes[i]);
              this.$store.mutations.updateLatestDate(this.firstLayerTime)
            }
            if (
              layerNodes[i].getElementsByTagName("Name")[0].innerHTML ===
              process.env.VUE_APP_GEOSERVER_SECOND_LAYER_NAME
            ) {
              this.secondLayerTime = this.getTimeFromXML(layerNodes[i]);
            }
          }
        });
    },
    getTimeFromXML(layerNode) {
      let dataURL = layerNode
        .getElementsByTagName("DataURL")[0]
        .getElementsByTagName("OnlineResource")[0]
        .getAttribute("xlink:href");
      let int = dataURL.split("/")[dataURL.split("/").length - 1];
      let dateString = int.split("_")[0];
      let year = dateString.substr(0, 4);
      let month = dateString.substr(4, 2);
      let day = dateString.substr(6, 2);
      let hour = dateString.substr(8, 2);
      let utcString = `${year}-${month}-${day}T${hour}:00:00Z`;
      return new Date(utcString);
    },
  },
  mounted() {
    this.getGeoserverTime();
  },
  watch: {
    secondLayerTime: function (value) {
      if (value === null) return;
      if (this.firstLayerTime === null) return;
      if (value.getTime() != this.firstLayerTime.getTime()) {
        this.$emit("disableButton", true);
      }
    },
  },
};
</script>

<style>
.algae-info-pane {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  width: 345px;
  height: 25px;
  text-align: center;
  background-color: #eeece0;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
  font-size: 12px;
  padding-top: 3px;
}
.pred-message {
  display: inline;
  font-weight: bold;
}
.percentage {
  display: inline;
  font-weight: bold;
  color: red;
}
.date-string {
  display: inline;
  font-weight: bold;
}
.coordinates {
  display: inline;
  font-weight: bold;
}
@media (max-width: 575px) {
  .algae-info-pane {
    bottom: 5px;
    width: 220px;
    font-size: 12px;
    height: 40px;
  }
}
</style>