const Layers = {
  TODAY: [process.env.VUE_APP_GEOSERVER_FIRST_LAYER_NAME,"1-24"],
  TOMORROW: [process.env.VUE_APP_GEOSERVER_SECOND_LAYER_NAME,"25-48"]
};
const Regions = [
  {
    name: "lowerStLawrenceEstuary",
    titlefr: "Estuaire du Saint-Laurent",
    titleen: "Lower St. Lawrence Estuary",
    color:"#d45133",
    darkColor:"#6c2616",

  },
  {
    name: "northWesternGulf",
    titlefr: " Nord-ouest du Golfe",
    titleen: "NW Gulf",
    color:"#f28b51",
    darkColor:"#963d0b",

  },
  {
    name: "lowerNorthShore",
    titlefr: "Basse Côte Nord",
    titleen: "Lower North Shore",
    color: "#26a99b",
    darkColor:"#13544d",
  },
  {
    name: "chaleurBay",
    titlefr: "Baie des Chaleurs",
    titleen: "Chaleur Bay",
    color:"#f0518b",
    darkColor:"#930c3d",
  },
  {
    name: "magdalenIslands",
    titlefr: "Îles-de-la-Madelaine",
    titleen: "Magdalen Islands",
    color:"#949d9c",
    darkColor:"#484f4e"
  },
  {
    name: "bayOfFundy",
    titlefr: "Baie de Fundy",
    titleen: "Bay of Fundy",
    color:"#50c5f1",
    darkColor:"#0b6f94",
  },
  {
    name: "princeEdwardIsland",
    titlefr: "Île-du-Prince-Édouard",
    titleen: "Prince Edward Island",
    color:"#c5d551",
    darkColor:"#6b761c",
  },
  {
    name: "scotianShelf",
    titlefr: "Plateau néo-écossais",
    titleen: "Scotian Shelf",
    color:"#268ac5",
    darkColor:"#134562",
  },
];

export { Layers, Regions };