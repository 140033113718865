<template>
  <div>
    <b-navbar id="header-core" toggleable="sm" variant="faded" type="light">
        <b-navbar-brand>
          <a class="logo-altox" v-if="this.$i18n.locale == 'en'" href="/">
            <img :src="`../assets/logo_altox.png`" class="d-inline-block align-top" alt="altox logo" id="logo"/>
          </a>
          <a class="logo-altox" v-if="this.$i18n.locale == 'fr'" href="/">
            <img :src="`../assets/logo_altox.png`" class="d-inline-block align-top" alt="altox logo" id="logo"/>
          </a>
          <div class="titre-altox">
            <span class="altox1">{{ $t("altox1") }}</span>
            <span class="altox2">Alexandrium catenella</span>
          </div>
        </b-navbar-brand>
        <b-navbar-toggle id="menuBurger" ref="menuBurger" target="nav-collapse" @click="show = !show">
          <template>
            <span class='icon-bar'></span>
            <span class='icon-bar'></span>
            <span class='icon-bar'></span>
          </template>
        </b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav >
            <b-nav-item id="switchLanguage" @click="switchLocale()">
              <img class="switchLogo" :src="`../assets/language-light.svg`"/>
              <span class="switchText">{{
                  $i18n.locale === "fr" ? "EN" : "FR"
                }}
              </span>
            </b-nav-item>
            <b-nav-item-dropdown id="dropdown-dropleft" no-caret>
              <template slot="button-content">
                <div id="main-menu">
                  <div class="logo-main-menu"/>
                  <span class="titre-main-menu">MENU</span>
                </div>
              </template>
              <b-dropdown-item to="#sectionIntro"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/><span id="menuGraphique" v-html="$t('intro_title')"></span></b-dropdown-item>
              <b-dropdown-item to="#sectionMap"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/>{{ $t("predictionMap_title") }}</b-dropdown-item>
              <b-dropdown-item to="#sectionGraphique"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/><span id="menuGraphique" v-html="$t('titreGrahique')"></span></b-dropdown-item>
              <b-dropdown-item to="#sectionAPropos"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/><span id="menuGraphique" v-html="$t('aboutTitle')"></span></b-dropdown-item>
              <b-dropdown-item to="#sectionMethodologie"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/>{{ $t("methodologyTitle") }}</b-dropdown-item>
              <b-dropdown-item to="#sectionDataArchiving"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/>{{ $t("dataArchivingTitle") }}</b-dropdown-item>
              <b-dropdown-item to="#sectionDisclaimerOfLiability"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/>{{ $t("dataArchivingSubTitle") }}</b-dropdown-item>
              <b-dropdown-item to="#sectionArticles"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/>{{ $t("articlesTitle") }}</b-dropdown-item>
              <b-dropdown-item to="#sectionRemerciement"><img class="main-submenu" :src="`../assets/altox_unique.svg`"/>{{ $t("aknowledgmentsTitle") }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      show:false
    }
    
  },
  methods: {
    switchLocale() {
      if (this.$i18n.locale === "fr") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "fr";
      }
    },
    coucou() {
      this.show = !this.show
    }
  },
};
</script>

<style>
  .dropdown-item {
    white-space: normal !important;
  }
  
  @media (min-width: 576px) {
    ul.dropdown-menu.show{
      top: 5px;
      left: -360px;
      width: 360px;
      z-index: 10001;
      border-radius :.5rem
    }
  }

  @media (max-width: 575px) {
    #dropdown-dropleft .nav-link {
      display: none !important;
    }

    ul.dropdown-menu {
      display: block;
    }

    ul.dropdown-menu,
    ul.dropdown-menu.show{
      background-color: #193835;
      border: 0;
    }

    .dropdown-item {
      color: #E5E3C5 !important;
    }
  }
  
  .dropdown-menu.show {
    background-color: #018786;
    border: 2px solid white;
  }

  .dropdown-item,
  #menuGraphique {
    color: #fff !important;
  }

  .dropdown-item:hover,
  .dropdown-item:active,
  .dropdown-item:focus {
    background-color: #193835 !important;
  }
</style>

<style scoped>

  #dropdown-dropleft .logo-main-menu {
    background: url("~/public/assets/altox_unique.svg");
    width: 32.53px;
    height: 35.3px;
  }

  #dropdown-dropleft.show .logo-main-menu {
    background: url("~/public/assets/altox_unique_show.svg") no-repeat center !important;
  }

  .navbar-light .navbar-toggler {
    border-color: #E5E3C5;
  }

  #menuBurger .icon-bar {
    display: block;
    width: 24px;
    height: 2px;
    margin: 7px auto;
    background-color: #E5E3C5;
  }
  
  .navbar-toggler-icon {
      color: #E5E3C5;
    }
  .navbar-brand {
    margin-left: 1rem;
    margin-right: 0;
  }
  #nav-collapse {
    justify-content: flex-end;
  }

  .main-submenu {
    width: 25px;
    margin-right: 10px;
  }

  .logo-main-menu{
    width: 32.54px;
  }

  #logo {
    width: 80px;
    margin-right: 15px;
  }

  .altox1 {
    font-weight: bold;
  }

  .altox2 {
    font-style: italic;
  }

  #header {
    background-color: #193835;
  }

  .navbar {
    max-width: 1140px;
    margin: 0 auto;
  }

  .navbar span{
    color: #E5E3C5 !important;
  }

  .navbar-brand {
    display: flex;
  }

  .titre-altox,
  #switchLanguage a,
  #main-menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  #switchLanguage {
    width: 60px;
  }

  .switchText,
  .titre-main-menu {
    display: flex; 
    justify-content: center;
  }

  .logo-main-menu {
    margin-left: auto;
    margin-right: auto;
  }

  #menuBurger {
    margin-right: 1rem;
  }

  @media (max-width: 575px) {
    .logo-main-menu,
    .switchLogo {
      display: none;
    }

    .switchText,
    .titre-main-menu {
      justify-content: left;
      padding-left: 1em;
    }
  }

  @media (max-width: 510px) {
    .navbar-brand {
      flex-direction: column;
      margin-left: 0;
    }

    .titre-altox {
      font-size: 15px;
    }

    .logo-altox {
      display: flex;
      justify-content: center;
    }

    #logo {
      margin-right: 0;
      margin-bottom: 10px;
    }

    #menuBurger {
      margin-right: 0rem;
    }
  }

</style>