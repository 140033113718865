function dateExtractor(date) {
  let hh = String(date.getHours()).padStart(2, "0");
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();
  return [yyyy, mm, dd, hh]
}
function formatDate(date) {
  const [yyyy, mm, dd, hh] = dateExtractor(date)
  return `${yyyy}-${mm}-${dd} ${hh}:00`;
}
function formatDateNoHours(date) {
  const [yyyy, mm, dd, hh] = dateExtractor(date)
  return `${yyyy}-${mm}-${dd}`;
}

export { formatDate, formatDateNoHours }