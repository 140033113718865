<template>
    <div></div>
</template>

<script>
import { Regions } from "@/constants";
import * as d3 from "d3";
export default {
    props: ["dataToDisplay", "dataDateMin", "dataDateMax"],
    data() {
        var _this = this;
        var line = d3
            .line()
            .x(function (d) {
                return _this.x(d.date);
            })
            .y(function (d) {
                return _this.y(d.value);
            });
        return {
            line,
            width: 950,
            height: 450,
            margin: { top: 40, bottom: 20, left: 100, right: 10 },
            content: null,
            dates: null,
            minYValue: null,
            maxYValue: null,
            extentX: null,
            svg: null,
            x: null,
            y: null,
            xAxis: null,
            lineFollowX: null,
            mouseMoveX: null,
            selectedData: {},
            dateHovered: null,
            xAxisBaseUnits: null,
            xTickInterval: null,
            format: null,
            tickOpacitySet: 0,
        };
    },
    methods: {
        clearContent: function () {
            let _this = this;
            this.content = document.getElementById("timeSeriesChart");

            // Clear the content
            var child = _this.content.lastElementChild;
            while (child) {
                _this.content.removeChild(child);
                child = _this.content.lastElementChild;
            }
        },
        initializeChart: function () {
            let _this = this;

            this.dates = this.dataToDisplay[
                Object.keys(_this.dataToDisplay)[0]
            ].map((a) => a.date);
            this.setXAxisParams();
            this.maxYValue = 100;
            this.minYValue = 0;
            // Max extent of the data
            this.extentX = d3.extent(
                this.dataToDisplay[Object.keys(_this.dataToDisplay)[0]],
                (d) => d.date
            );

            this.calculateAxisRange();
            this.initiateSVG();
        },
        setXAxisParams() {
            let listDates = [];
            let startDate = this.dates[0];
            let endDate = this.dates[this.dates.length - 1];
            let dateMove = new Date(startDate);
            dateMove.setHours(0, 0, 0, 0);
            while (dateMove <= endDate) {
                let newDate = new Date(dateMove)
                listDates.push(newDate);
                dateMove.setDate(dateMove.getDate() + 1);
            }
            let a = 9 / 140;
            let b = 5 / 14;
            this.xTickInterval = Math.ceil(a * listDates.length + b);
            if (listDates.length < 160) {
                this.xAxisBaseUnits = "days";
                this.format = "%Y-%m-%d";
                this.tickOpacitySet = 1;
            } else {
                this.xAxisBaseUnits = "months";
                this.format = "%Y-%m";
                this.tickOpacitySet = 1;
            }
        },
        calculateAxisRange() {
            // Set x axis
            this.x = d3
                .scaleTime()
                .range([this.margin.left, this.width - this.margin.right])
                .domain(this.extentX);

            // Set y axis
            this.y = d3
                .scaleLinear()
                .domain([this.minYValue, this.maxYValue])
                // .domain(d3.extent(this.baseData, (d) => d.value))
                .range([this.height - this.margin.bottom, this.margin.top]);
        },
        initiateSVG() {
            this.svg = d3
                .select(this.content)
                .append("svg")
                .attr("class", "svg-content-responsive")
                .attr("preserveAspectRatio", "xMinYMin meet")
                .attr("viewBox", "0 0 1000 550");
        },

        fillChart: function () {
            if (this.svg === null) return;
            this.svg.append("g");
            for (const timeSeries in this.dataToDisplay) {
                //Display prediction lines
                this.svg
                    .append("path")
                    .data([this.dataToDisplay[timeSeries]])
                    .attr("class", "line")
                    .attr("d", this.line)
                    .style("stroke-width", 4)
                    .style(
                        "stroke",
                        Regions.filter(
                            (region) => region.name === timeSeries
                        )[0].color
                    )
                    .style("fill", "none");
            }
        },
        setAxis: function () {
            //configure xaxis
            const xAxisPosition = this.height - this.margin.bottom;
            // const format = d3.timeFormat("%Y-%m-%d");
            if (this.xAxisBaseUnits === "days") {
                this.xAxis = d3
                    .axisBottom(this.x)
                    .ticks(d3.timeDay.filter(d=>d3.timeDay.count(0, d) % this.xTickInterval === 0), this.format);
            } else {
                this.xAxis = d3
                    .axisBottom(this.x)
                    .ticks(d3.timeMonth.every(2), this.format);
            }

            this.svg
                .append("g")
                .attr("class", "xaxis")
                .attr("transform", "translate(0," + xAxisPosition + ")")
                .call(this.xAxis)
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-65)")
                .style("font-size", 20)
                .style("stroke", "black")
                .style("opacity", this.tickOpacitySet)
                .selectAll("line")
                .style("stroke", "black")
                .selectAll("path")
                .style("stroke", "black");

            //Configure yaxis
            this.svg
                .append("g")
                .attr("class", "yaxis")
                .attr("transform", "translate(" + this.margin.left + ",0)")
                .call(d3.axisLeft(this.y).ticks(6))
                .style("font-size", 20)
                .style("stroke", "black");

            d3.selectAll(".xaxis line").style("stroke", "black");
            d3.selectAll(".yaxis line").style("stroke", "black");

            this.addYAxisLabel();
        },
        addYAxisLabel: function () {
            // text label for the y axis
            this.svg
                .append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", this.margin.left - 90)
                .attr("x", 0 - this.height / 2)
                .attr("dy", "1em")
                .attr("id", "yLabel")
                .style("text-anchor", "middle")
                .text(this.$t("chartYLabel"))
                .style("font-size", 30);
        },
        addInteractions: function () {
            //configure line following mouse along xAxis
            this.lineFollowX = this.svg
                .append("path")
                .attr("class", "lineFollow")
                .style("stroke-width", 4)
                .style("stroke", "black")
                .style("fill", "none")
                .style("stroke-dasharray", "3, 3")
                .style("opacity", 1);

            // Create the circles that travel along the curve of chart
            for (const timeSeries in this.dataToDisplay) {
                let color = Regions.filter(
                    (region) => region.name === timeSeries
                )[0].color;
                this.svg
                    .append("g")
                    .append("circle")
                    .style("fill", color)
                    .attr("id", "focus" + timeSeries)
                    .attr("r", 10)
                    .style("opacity", 1);

                // Create the text that travels along the curve of chart
                this.svg
                    .append("g")
                    .append("text")
                    .style("opacity", 1)
                    .attr("id", "text" + timeSeries)
                    .attr("text-anchor", "left")
                    .attr("alignment-baseline", "middle")
                    .style("fill", color)
                    .style("font-size", "20px");
            }

            // Create a rect on top of the svg area: this rectangle recovers mouse position
            this.rect = this.svg
                .append("rect")
                .style("fill", "none")
                .style("pointer-events", "all")
                .attr("class", "chartRect")
                .on("mouseover", this.mouseover)
                .on("mousemove", this.mousemove)
                .on("mouseout", this.mouseout);
        },
        mousemove: function (event) {
            this.findDateRelated(event);
            if (this.dateHovered === undefined) return;
            this.mouseMoveX = [
                {
                    date: this.dateHovered,
                    value: 0,
                },
                {
                    date: this.dateHovered,
                    value: 100,
                },
            ];

            this.lineFollowX.data([this.mouseMoveX]).attr("d", this.line);
        },
        findDateRelated: function (event) {
            var pointer = d3.pointer(event, event.target);
            var x0 = this.x.invert(pointer[0]);
            var i = d3.bisect(this.dates, x0);
            this.dateHovered = this.dates[i];
            if (this.dateHovered === undefined) return;
            for (const timeSeries in this.dataToDisplay) {
                let value = this.dataToDisplay[timeSeries][i].value;
                let focusId = `#focus${timeSeries}`;
                d3.select(focusId)
                    .attr("cx", this.x(this.dateHovered))
                    .attr("cy", this.y(value));
                let textId = `#text${timeSeries}`;
                d3.select(textId)
                    .html(Math.round(value))
                    .attr("x", this.x(this.dateHovered) + 10)
                    .attr("y", this.y(value) - 15);
            }
            return this.dateHovered;
        },
        buildEmptyChart() {
            this.maxYValue = 100;
            this.minYValue = 0;
            // Max extent of the data
            this.extentX = [
                new Date(this.dataDateMin),
                new Date(this.dataDateMax),
            ];

            this.calculateAxisRange();
            this.initiateSVG();
            this.setAxis();
        },
        displayGraph: function () {
            this.clearContent();
            if (Object.keys(this.dataToDisplay).length === 0) {
                this.buildEmptyChart();
                return;
            }
            this.initializeChart();
            if (this.svg === null) return;
            this.fillChart();
            this.setAxis();
            this.addInteractions();
        },
    },
    watch: {
        dataToDisplay: function () {
            this.displayGraph();
        },
        dataDateMin: function () {
            this.displayGraph();
        },
        "$i18n.locale": function () {
            d3.select("#yLabel").text(this.$t("chartYLabel"));
        },
    },
};
</script>

<style>
path.domain {
    stroke: black;
}
.chartRect {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>